import { useState, useEffect } from "react";
import {   useWeb3ModalProvider,
  useWeb3ModalAccount,
createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { parseUnits,formatEther,BrowserProvider, Contract,JsonRpcProvider, formatUnits, parseEther } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import {
  tokenabi,
  tokenaddress,
    wbtc,
    weth,
    busd,
    usdt,
    usdc,
    erc20abi,
} from "./constant";


export function Ido() {
    const projectId = '000a1b24652483ad57b194801f5591ac';
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
    const [bnbPrice , setbnbPrice] = useState();
    const [btcPrice , setbtcPrice] = useState();
    const [tokenPrice , settokenPrice] = useState();
    const [ethPrice , setethPrice] = useState();
    const [ selectedCurrencyUserBalance , setselectedCurrencyUserBalance] = useState();
	const [ userUkcBalance , setuserUkcBalance] = useState(0);
	const [ selectedCurrency , setSelectedCurrency] = useState();
	const [ input , setinput ] = useState();
	const [ tokenInput , settokenInput ] = useState();
	const [ ukcInput , setukcInput ] = useState();
    const [approval , setapproval] = useState();
    const [btn , setbtn] = useState();
	const [showNoti , setshowNoti] = useState("none");
	const [notiMessage , setnotiMessage] = useState("none");
	const [notiClass , setnotiClass] = useState("danger");
	const [buyBtnText, setbuyBtnText] = useState("Select Currency");
	
	


// 2. Set chains
const mainnet ={
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org'
  /*
  chainId: 97,
  name: 'Binance Smart Chain Testnet',
  currency: 'tBNB',
  explorerUrl: 'https://testnet.bscscan.com',
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'*/
}

const bscRpc = "https://bsc-dataseed.binance.org/";
const validChainId = 56;
// 3. Create modal
const metadata = {
  name: 'uckccoin',
  description: 'uckccoin',
  url: 'https://test.ukccoin.io/', // origin must match your domain & subdomain
  icons: ['https://test.ukccoin.io/html/images/UKC_Logo.svg']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


const showHideMenu = async () =>{
   
    $(".UKC-menu").toggleClass("show");
 
}

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
const selectCurrency = async (e) =>{
    //setSelectedCurrency(e.target.value);
    
    try {
		let  getTokenAddr ;
            const provider = new JsonRpcProvider(bscRpc);

		const signer = await getSignerOrProvider(true);
        if(e.target.value==""){
            setselectedCurrencyUserBalance("");
			setbuyBtnText("Select Currency");
        }
		if(e.target.value=="1"){
			getTokenAddr = wbtc;
		}
		else if(e.target.value=="2"){
			getTokenAddr = weth;
		}
		else if(e.target.value=="3"){
			getTokenAddr = busd;
		}
		else if(e.target.value=="4"){
			getTokenAddr = usdt;
		}
		else if(e.target.value=="5"){
			getTokenAddr = usdc;
		}
        setukcInput("");
        settokenInput("");
       setSelectedCurrency(e.target.value);
         if(e.target.value =="0"){
            const balance = await provider.getBalance(address);
            const balanceInEther = formatEther(balance);
			setselectedCurrencyUserBalance(balanceInEther);
			setbuyBtnText("Buy");
        }else {
			const contract = new Contract(getTokenAddr, erc20abi, provider);
			let balance = await contract.balanceOf(address);
			balance = Number(balance);
			balance = balance/(10**18);
			balance = balance.toFixed(2);
			setselectedCurrencyUserBalance(balance);
			setbuyBtnText("Approve & Buy");
        }

    } catch (err) {
      console.error(err);
    }

}

const getBalance = async() => {
	const provider = new JsonRpcProvider(bscRpc);
	const signer = await getSignerOrProvider(true);
	let balance = 0;
    if(selectedCurrency==null){
        balance = 0
    }
	else if(selectedCurrency==0){
		 balance = await provider.getBalance(address);
         balance = formatEther(balance);
	}
	else {
		let getTokenAddr;
		if(selectedCurrency=="1"){
			getTokenAddr = wbtc;
		}
		else if(selectedCurrency=="2"){
			getTokenAddr = weth;
		}
		else if(selectedCurrency=="3"){
			getTokenAddr = busd;
		}
		else if(selectedCurrency=="4"){
			getTokenAddr = usdt;
		}
		else if(selectedCurrency=="5"){
			getTokenAddr = usdc;
		}
		const contract = new Contract(getTokenAddr, erc20abi, provider);
		balance = await contract.balanceOf(address);
		balance = Number(balance);
		balance = balance/(10**18);
		balance = balance.toFixed(4);
		
	}
	return balance;
	
}


  const resetNotification = () => {
	  setTimeout(() => {
		  setshowNoti("none");
		}, 10000);
  }
  
  const updateNotification = (nMessage,nClass,nDisplay) => {
		setnotiMessage(nMessage);
		setnotiClass(nClass);
		setshowNoti(nDisplay);
		resetNotification();
  }
  
const buy =async () =>{
		
			if(selectedCurrency == undefined || tokenInput.length == 0 || tokenInput<=0){
				updateNotification("Select Currency","danger","block");
				return ;
			}
       // try {
            if(tokenInput == undefined || tokenInput.length == 0 || tokenInput<=0){
				updateNotification("Invalid Amount","danger","block");
				return ;
			}
            if(selectedCurrency =="0"){
				var tokenBalance = await getBalance();
				//alert(tokenBalance);
				if(tokenBalance<tokenInput){
					updateNotification("Insufficient Balance","danger","block");
					return ;
				}
				const signer = await getSignerOrProvider(true);
				const provider = await getSignerOrProvider();
				const inputamount =parseUnits(tokenInput,18)
				const contract = new Contract(tokenaddress,tokenabi, provider);
				const valueInWei = parseEther(tokenInput.toString());
				const balance = await contract.buyTokenWithbnb({ value: valueInWei});
				await balance.wait();
				updateNotification("Success","success","block");
				
            }else{
				const signer = await getSignerOrProvider(true);
				const provider = await getSignerOrProvider();
				const inputamount =parseUnits(tokenInput,18);
				
				
				let getTokenAddr;
				if(selectedCurrency=="1"){
					getTokenAddr = wbtc;
				}
				else if(selectedCurrency=="2"){
					getTokenAddr = weth;
				}
				else if(selectedCurrency=="3"){
					getTokenAddr = busd;
				}
				else if(selectedCurrency=="4"){
					getTokenAddr = usdt;
				}
				else if(selectedCurrency=="5"){
					getTokenAddr = usdc;
				}
				
				let contract = new Contract(getTokenAddr, erc20abi, provider);
				let balance = await contract.approve(tokenaddress,inputamount);
				await balance.wait();
				
				 contract = new Contract(tokenaddress, tokenabi, provider);
				const valueInWei = parseEther(tokenInput.toString());
				 balance = await contract.buyWithToken(inputamount , selectedCurrency);
				await balance.wait();
				updateNotification("Success","success","block");
			}
    /*} catch (err) {
        alert(err);
      console.error(err);
    }*/
  


}


const investedUser = async() =>{
	
		
		const signer = await getSignerOrProvider(true);
        
		console.log("signer",signer.address);
		const provider = await getSignerOrProvider();
		const contract = new Contract(tokenaddress, tokenabi, provider);
		var result = await contract.investors(signer);
		console.log("result",result);
		var getInvested = Number(result.invested)/(10**18);
		getInvested = getInvested.toFixed(8);
		setuserUkcBalance(getInvested);
		
		
		
		var result = await contract.userDeposits(signer);
			 var tbody = "";
			 for(var i=0; i < result.amounts.length; i++){
				 var srNo = i+1;
				 var amount = Number(result.amounts[i]);
				 amount = amount/(10**18);
                 amount = amount.toFixed(8);
				 var UKCToken = Number(result.getTokens[i]);
				 UKCToken = UKCToken/(10**18);
                 UKCToken = UKCToken.toFixed(8);
				 var time = Number(result.times[i]);
				 var timeFormat = moment(time*1000).format("DD-MM-YYYY h:mm:ss");
				 var currency = "";
				 if(result.tariffs[i]==0){ currency = "BNB"; }
				 if(result.tariffs[i]==1){ currency = "WBTC"; }
				 if(result.tariffs[i]==2){ currency = "WETH"; }
				 if(result.tariffs[i]==3){ currency = "BUSD"; }
				 if(result.tariffs[i]==4){ currency = "USDT"; }
				 if(result.tariffs[i]==5){ currency = "USDC"; }
				 tbody = tbody + "<tr><td>"+srNo+"</td><td>"+currency+"</td><td>"+amount+"</td><td>"+UKCToken+"</td><td>"+timeFormat+"</td>/tr>";
			 }
			 $("#table_body").html(tbody);
		
	
}



const busdPrice = async(e) => {
	var busdAmt = e.target.value;
	
	settokenInput(busdAmt);
//await checkApproval();
	var getVal = selectedCurrency;
	if(getVal=="0"){
		var res = (busdAmt*bnbPrice)/(tokenPrice*100000000);
	}
	else {
		var otherTokenPrice = 1;
		if(getVal=="1"){
			otherTokenPrice = btcPrice;
		}
		else if(getVal=="2"){
			otherTokenPrice = ethPrice;
		}
		var res     = busdAmt*otherTokenPrice/tokenPrice;
		
	}
	
    res          = res.toFixed(6);
	
   setukcInput(res);
}
const reverceBusdPrice = async(e) => {
	var tokenAmt = e.target.value;
	setukcInput(tokenAmt);
	var getVal = selectedCurrency;
	if(getVal=="0"){
		var res = (tokenAmt*100000000*tokenPrice)/(bnbPrice);
	}
	else {
		var otherTokenPrice = 1;
		if(getVal=="1"){
			otherTokenPrice = btcPrice;
		}
		else if(getVal=="2"){
			otherTokenPrice = ethPrice;
		}
		var res     = (tokenAmt*tokenPrice)/otherTokenPrice;
	}
	
	res          = res.toFixed(8);
	settokenInput(res);
}


  const getAllPrice = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const result = await contract.allPrice();
      // console.log(formatUnits(balance,18),"bala")
	  
	    let bnbPrice = Number(result[0]); //bnbPRice
		
		let tokenPrice    = Number(result[1]); 
		let tokenPriceDecimalVal  = Number(result[2]) ;
		let tokenPriceDecimal  = Math.pow(10, tokenPriceDecimalVal);
		let price = tokenPrice/tokenPriceDecimal;
		let priceLatest = Number(price).toFixed(tokenPriceDecimalVal).replace(/\.?0+$/,"");

		let wbtcTokenPrice    = Number(result[3]); 
		let wbtcTokenPriceDecimalVal  = Number(result[4]) ;
		let wbtcTokenPriceDecimal  = Math.pow(10, wbtcTokenPriceDecimalVal);
		let wbtcPrice = wbtcTokenPrice/wbtcTokenPriceDecimal;
		let wbtcPriceLatest = Number(wbtcPrice).toFixed(wbtcTokenPriceDecimalVal).replace(/\.?0+$/,"");

		let wethTokenPrice    = Number(result[5]); 
		let wethTokenPriceDecimalVal  = Number(result[6]) ;
		let wethTokenPriceDecimal  = Math.pow(10, wethTokenPriceDecimalVal);
		var wethPrice = wethTokenPrice/wethTokenPriceDecimal;
		let wethPriceLatest = Number(wethPrice).toFixed(wethTokenPriceDecimalVal).replace(/\.?0+$/,"");
        
		setbnbPrice(bnbPrice);
        setethPrice(wethPriceLatest);
        setbtcPrice(wbtcPriceLatest);
        settokenPrice(priceLatest);
       



    } catch (err) {
      console.error(err);
    }
  };


useEffect( () => {
    	if (isConnected && chainId==validChainId) {
            getAllPrice();
            investedUser();
         
            if(chainId != validChainId){
              //	  alert("switch to bNb mainnet");
				//updateNotification("switch to bNb mainnet","danger","block");
				
				//alert(chainId);
            }

	}
    if(!isConnected){
        setSelectedCurrency("");
        setukcInput("");
        settokenInput("");
        setuserUkcBalance("");
        setselectedCurrencyUserBalance("");
        $("#currency_id").val("");
        $("#table_body").html();
    }
});


  return (<div>
   <div class="UCK-header">
        <div class="container">
            <div class="UKC-inner-header d-grid">
                <div class="UKC-logo">
                    <a href="/">
                        <img class="img-resonsive" src="/html/images/UKC_Logo.svg" alt="UKC" />
                    </a>
                </div>
                <div class="UKC-menu">
                    <ul>
                        <li class="menu-item">
                            <a class="text-uppercase " href="/">home</a>
                        </li>
                        <li class="menu-item">
                            <a class="text-uppercase   active " href="/ido">get ukc coin</a>
                        </li>
                      
                    </ul>
                </div>

                <div class="menu-toggle mobile-only" onClick={showHideMenu}><span class="toggle-btn"></span></div>
              
            </div>
        </div>
    </div>
    <div class="UKC-body ido-body">
        <div class="loader-wrapper d-none" id="loader">
            <div class="loader">Loading...</div>
        </div>

        <div class="container pt-md-4 pb-4">
            <span class="overlay-select-option d-none"></span>

            <div class="UKC-middle-box d-none" id="confirmed-div">
                <div class="theme-alert">
                    TRANSACTION CONFIRMED! <br />
                    TRANSACTION DETAILS ARE AVAILABLE <a href="" id="transaction-link" target="_blank"> HERE</a>
                </div>
                <div class="create-account-page-link">
                    <a href="./create-account/"><img class="img-responsive" id="fanzone" src="images/Fanzone_Ad.jpg"
                            alt="" /></a>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-6 mb-4 mb-md-0">
                    <div class="UKC-middle-box" id="buy-div">
                        <div class="select-currency-wrapper">
                            <div class="wallet-code-disconnects mb-1 text-right" id="conn-status">
       <w3m-button balance="hide"/>
                            </div>
                            <div class="currency-select-wrap mb-1">
                                <div class="d-flex">
                                    <div class="drop-down">
                                        <select class="form-control" onChange={selectCurrency} id="currency_id" name="languages"
                                           >
                                            <option value="">Select Currency</option>
                                            <option value="0">BNB</option>
                                            <option value="1">WBTC (BEP20)</option>
                                            <option value="2">WETH (BEP20)</option>
                                            <option value="3">BUSD (BEP20)</option>
                                            <option value="4">USDT (BEP20)</option>
                                            <option value="5">USDC (BEP20)</option>
                                        </select>
                                        Balance : <span id="busd_balance">{selectedCurrencyUserBalance}</span>

                                    </div>
                                    <div>
                                        <input type="number" step="0.1" onChange={busdPrice}
                                            min="0" placeholder="0" value={tokenInput} id="busdAmtIdo" />
                                    </div>
                                </div>
                                <div class="UKC-coin-convert mb-2">
                                    <div class="d-flex">
                                        <div class="ukc-icon">
                                            <div class="selected">
                                                <img src="images/UKC-icon.png" alt="" /> UKC
                                            </div>
                                            Balance : <span class="token_balance">{userUkcBalance}</span>
                                        </div>
                                        <div>
                                            <input type="number" class="reverceBusdPrice" onChange={reverceBusdPrice}  min="0"
                                                placeholder="0" value={ukcInput}  id="tokenAmtIdo2" />
                                        </div>
                                    </div>
                                </div>
								<div className={"alert alert-"+notiClass} style={{display:showNoti}}>{notiMessage}</div>
								
								
     
							<div className="mb-2 text-center">
								<button className="UKC-btn connect-wallet-btn busdIdoBuy" 
									id="btn-connect-wallet" onClick={buy}>{buyBtnText}</button>
							</div>



                                <div class="bnb-copyright">
                                    <img src="images/BNB-gray.png" alt="" /> POWERED BY BINANCE SMART CHAIN
                                </div>
                                <div class="theme-alert danger-alert verified-account-alert position-relative d-none"
                                    id="currency-error">
                                    <span class="custom-close"></span>
                                    <div id="currency-text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="UKC-middle-box" id="buy-div">
                            <div class="select-currency-wrapper">
                                <div class="wallet-code-disconnects mb-1 text-right" id="conn-status">

                                </div>
                                <div class="currency-select-wrap mb-1 table_scrool">

                                    <table class="table table-striped " style={{color:"#fff"}}>
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>Currency</th>
                                                <th>Currency Amount</th>
                                                <th>UKC </th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody id="table_body">

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
   
    <div class="UKC-footer">
        <div class="container">
            <div class="text-center">
                <ul class="social-links">
                    <li><a class="twitter social" href="https://x.com/ukc_global?t=6ofKO4Ezb4R-yL1ZdkQ_6g&amp;s=09"
                            target="_blank"></a></li>
                    <li><a class="instagram social"
                            href="https://www.instagram.com/ultimatekricketchallenge?igsh=dmZrbDNrZHk4Z2M3"
                            target="_blank"></a></li>
                    <li><a class="github social" href="https://github.com/ukccoin/" target="_blank"></a></li>
                   
                </ul>
            </div>
        </div>
    </div>

    <div id="wallet-connect-popup" class="wallet-connect-popup" >
        <div class="popup-cotent" style={{pointerEvents: 'all', zIndex: '999'}} >
            <div class="text-center text-white">
                <h3 class="mb-0 mt-0">UH OH! </h3>
                <h4 class="mt-1">IT LOOKS LIKE YOU NEED A SUPPORTED WALLET</h4>
                <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                    target="_blank" style={{textDecoration: 'none', color: 'white'}}>
                    <div class="p-1 metamask">
                        <img src="images/metamask-fox.png" alt="" />
                        <h2 class="mb-0">INSTALL METAMASK</h2>
                    </div>
                </a>
                
            </div>
        </div>
    </div>
    <div class="ukc-popup"></div>


  </div>);
}
