import React from 'react';
import {  Link } from 'react-router-dom';
import $ from 'jquery';
 
export function Home() {

  const showHideMenu = async () =>{
   
      $(".UKC-menu").toggleClass("show");
   
  }
  return (<div>
  
        
    <div className="UCK-header">
      <div className="container">
        <div className="UKC-inner-header d-grid">
          <div className="UKC-logo">
            <Link to="/">
              <img className="img-resonsive" src="html/images/UKC_Logo.svg" alt="UKC" />
            </Link>
          </div>
          <div className="UKC-menu">
            <ul>
              <li className="menu-item">
                <a className="text-uppercase " href="#">EVENTS</a>
              </li>
              <li className="menu-item">
                <Link className="text-uppercase   " to="/ido">Store</Link>
              </li>
              <li className="menu-item">
                <a className="text-uppercase " href="/">NFTS</a>
              </li>
              <li className="menu-item">
                <a className="text-uppercase fanzone-btn" href="/">
                  <span className="position-relative">Token Launch</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="menu-toggle mobile-only" onClick={showHideMenu}>
            <span className="toggle-btn"></span>
          </div>
        </div>
      </div>
    </div>
    <div className="UKC-body">
      <div className="loader-wrapper d-none" id="loader">
        <div className="loader">Loading...</div>
      </div>
      <section className="home noscroll" id="home">
        <img className="img-resonsive w100" src="html/images/bannerbg.jpg" alt="bannerbg" />
        <div className="home_banner">
          <div className="container">
            <div className="row">
              <div className="content">
                <h3>Unlock the Future of <br /> Cricket with UKC and UKCCOIN </h3>
                <p>UKC COIN is bringing fans of one of the <br /> most popular global sport closer to the <br />action. </p>
                <Link to="/ido" className="fanzone-btn">
                  <span className="position-relative">GET UKC COIN</span>
                </Link>
                <a href="#" className="content-link-btn">
                  <span className="position-relative">LEARN MORE</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="radial-img">
          <img className="img-resonsive" src="html/images/logo-coin.png" alt="UKC" />
        </div>
      </section>
      <section className="icons-container core-feature over_vission">
        <div className="container">
          <div className="content">
            <h3>OVERVIEW &amp; VISION</h3>
            <p> UKC, a global phenomenon launched in 2020 and based in the UAE, transcends traditional cricket experiences. Boasting a robust roster of cricket's biggest stars, UKC events have garnered over 3 billion YouTube views and 1 billion Facebook views. The patented cricket format with exclusive IP rights elevates the UKC to a league of its own. </p>
            <div className="video-container event-col">
              <iframe src="https://www.youtube.com/embed/_3sHkybuQk4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
              <img className="aeroleft" src="html/images/aeroleft.png" alt="" />
              <img className="aeroright" src="html/images/aeroright.png" alt="" />
            </div>
          </div>
        </div>
        <img className="left_bg" src="html/images/left_bg.png" alt="" />
        <img className="right_bg" src="html/images/right_bg.png" alt="" />
      </section>
      <section className="icons-container core-feature">
        <div className="container">
          <h2>CORE FEATURES</h2>
          <div className="row">
            <div className="icons">
              <img src="html/images/i1.png" alt="" />
              <div className="info">
                <h3>True Fan Engagement </h3>
                <span>Utilizing blockchain technology to empower fans with voting rights in the governance of UKC events. Choose the where, who, and when, making fans the ultimate reference point for UKC. </span>
              </div>
            </div>
            <div className="icons">
              <img src="html/images/i2.png" alt="" />
              <div className="info">
                <h3>The First Easy-to-Use Cricket Cryptocurrency - UKCCOIN </h3>
                <span> Earn and trade virtual goods seamlessly within the thriving UKC community platform. Experience a revolutionary sporting dimension with real ownership and tangible value. </span>
              </div>
            </div>
            <div className="icons">
              <img src="html/images/i3.png" alt="" />
              <div className="info">
                <h3>Backed by Communities and Cricketers </h3>
                <span> Join millions of engaged fans working together to create a decentralized voice and virtual economies. UKCCOIN empowers fans to monetize content, fostering a community-driven culture of passion and collaboration. </span>
              </div>
            </div>
            <div className="icons">
              <img src="html/images/i4.png" alt="" />
              <div className="info">
                <h3>Sporting Value </h3>
                <span> Promoting a culture of passion, collaboration, and pride among players. UKCCOIN provides players with more control over their content, ensuring fans feel valued, and leading to increased revenue and engagement. </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="purpose_section tc">
        <img className="left_bg" src="html/images/left_bg.png" alt="" />
        <div className="container">
          <h2 className="hadding">Purpose</h2>
          <p> Addressing the gap between fans and the game, UKCCOIN introduces a cryptocurrency (BEP-20 token) to bridge the community and cricket stars. It empowers fans to engage directly with stars, providing crypto-backed value and tools for mutual interaction. </p>
        </div>
      </section>
      <section className="fanzone-banner">
        <div className="container">
          <div className="banner">
            <img src="html/images/aw.png" alt="" />
            <h2 className="hadding tc">UKC FANZONE</h2>
            <p> Holders of the token can explore the Fan Zone, where they have the ability to oversee their tokens and participate in exclusive promotions. One exciting opportunity includes the chance to spend a day with the renowned UKC stars and so much more. </p>
          </div>
          <div className="events_box">
            <img src="html/images/events.png" alt="events" />
            <h2 className="hadding ">UKC FANZONE</h2>
            <p> Holders of the token can explore the Fan Zone, where they have the ability to oversee their tokens and participate in exclusive promotions. One exciting opportunity includes the chance to spend a day with the renowned UKC stars and so much more. </p>
            <a href="#">VIEW UPCOMING EVENTS</a>
            <br />
            <br />
            <h2 className="hadding ">Events</h2>
            <h3 className="hadding2 ">Live Head-to-Head Showdowns</h3>
            <p> Witness the world's top cricket stars battling it out in real-time. Unforgettable moments unfold in live events broadcast globally, bringing fans closer to the action. </p>
          </div>
        </div>
      </section>
      <section className="star_box tc">
        <div className="container">
          <h2 className="hadding tc">FOLLOW THE STARS</h2>
          <div className="user_boxs">
            <div className="user_box">
              <img src="html/images/t1.png" className="img-resonsive" alt="events" />
            </div>
            <div className="user_box">
              <img src="html/images/t2.png" className="img-resonsive" alt="events" />
            </div>
            <div className="user_box">
              <img src="html/images/t3.png" className="img-resonsive" alt="events" />
            </div>
            <div className="user_box">
              <img src="html/images/t4.png" className="img-resonsive" alt="events" />
            </div>
          </div>
          <div className="text-center">
            <a href="/get-ukc-coin/" className="fanzone-btn">
              <span className="position-relative">GET UKC COIN</span>
            </a>
          </div>
        </div>
      </section>
      <div className="UKC-footer">
        <div className="container">
          <div className="text-center">
            <ul className="social-links">
              <li>
                <a className="twitter social" href="https://x.com/ukc_global?t=6ofKO4Ezb4R-yL1ZdkQ_6g&amp;s=09" target="_blank"></a>
              </li>
              <li>
                <a className="instagram social" href="https://www.instagram.com/ultimatekricketchallenge?igsh=dmZrbDNrZHk4Z2M3" target="_blank"></a>
              </li>
              <li>
                <a className="github social" href="https://github.com/ukccoin/" target="_blank"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="wallet-connect-popup" className="wallet-connect-popup">
        <div className="popup-cotent" style={{pointerEvents: 'all', zIndex: 999}}>
          <div className="text-center text-white">
            <h3 className="mb-0 mt-0">UH OH! </h3>
            <h4 className="mt-1">IT LOOKS LIKE YOU NEED A SUPPORTED WALLET</h4>
            <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank" style={{textDecoration: 'none', color: 'white'}}>
              <div className="p-1 metamask">
                <img src="html/images/metamask-fox.png" alt="" />
                <h2 className="mb-0">INSTALL METAMASK</h2>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="ukc-popup"></div>
    </div>
  </div>);
}
