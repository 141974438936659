import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Routes from react-router-dom
import { Home } from './Home';
import { Ido } from './Ido';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes> {/* Wrap Routes around Route components */}
          <Route path='/' element={<Home />} /> {/* Specify element prop instead of component prop */}
          <Route path='/ido' element={<Ido />} /> {/* Specify element prop instead of component prop */}
        </Routes>
      </Router>
	 
        <ToastContainer />
    </div>
	
  );
}

export default App;

